var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cookies-overview"
  }, [_vm.scopeQs ? _c('div', [_c('Row', {
    staticStyle: {
      "margin-bottom": "24px"
    },
    attrs: {
      "type": "flex",
      "gutter": 24
    }
  }, [_vm.userHasRole('publisher-fp') ? _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "cookie",
      "description": {
        title: _vm.$t('global.cookie.exempted_cookies')
      },
      "excluded-filters": ['isExempted'],
      "params": {
        limit: 1,
        is_exempted: true,
        isFirstParty: 1
      },
      "type": "ONE_VALUE"
    }
  })], 1) : _vm._e()], 1), !_vm.userHasRole('publisher-fp') ? _c('Row', {
    staticStyle: {
      "margin-bottom": "24px"
    },
    attrs: {
      "type": "flex",
      "gutter": 24
    }
  }, [_c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 8
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "cookie",
      "description": {
        title: _vm.$t('global.cookie.all_detected')
      },
      "params": {
        limit: 1
      },
      "type": "ONE_VALUE"
    }
  })], 1), _c('Col', {
    attrs: {
      "md": 8,
      "xs": 24,
      "sm": 24
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "cookie",
      "description": {
        title: _vm.$t('global.cookie.first_party_t')
      },
      "params": {
        isFirstParty: 1,
        limit: 1
      },
      "type": "ONE_VALUE"
    }
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 8
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "cookie",
      "description": {
        title: _vm.$t('global.cookie.third_party_t')
      },
      "params": {
        isFirstParty: 0,
        limit: 1
      },
      "type": "ONE_VALUE"
    }
  })], 1)], 1) : _vm._e(), !_vm.userHasRole('publisher-fp') ? _c('Row', {
    attrs: {
      "type": "flex",
      "gutter": 24
    }
  }, [_c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12
    }
  }, [_c('WidgetTile', {
    staticStyle: {
      "min-height": "500px"
    },
    attrs: {
      "api": "wcookies/cookies08",
      "is-actionable": true
    }
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12
    }
  }, [_c('WidgetTile', {
    attrs: {
      "api": "wcookies/cookies15",
      "is-actionable": true
    }
  })], 1)], 1) : _c('Row', {
    attrs: {
      "type": "flex"
    }
  }, [!_vm.userHasRole('publisher-fp') ? _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12
    }
  }, [_c('div', [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "cookie",
      "description": {
        title: _vm.$t('global.cookie.exempted_cookies')
      },
      "excluded-filters": ['isExempted'],
      "params": {
        limit: 1,
        is_exempted: true,
        isFirstParty: 1
      },
      "type": "ONE_VALUE"
    }
  }), _c('br'), _c('WidgetTile', {
    attrs: {
      "api": "wcookies/cookiesCountForSimilarProperties?isFirstParty=1",
      "widget-style": 'BAR',
      "is-actionable": true
    }
  })], 1)]) : _vm._e(), _c('Col', {
    staticClass: "small-widget-col",
    attrs: {
      "lg": 12,
      "md": 24,
      "sm": 24,
      "xs": 24
    }
  }, [_c('div', [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "cookie",
      "description": {
        title: _vm.$t('Trackers dropped without explicit choice')
      },
      "params": {
        limit: 1,
        cmpScenarioOutcomes: ['NO_ACTION']
      },
      "type": "ONE_VALUE"
    }
  }), _c('br'), _c('WidgetTile', {
    staticStyle: {
      "min-height": "500px"
    },
    attrs: {
      "api": "wcookies/cookies08?isFirstParty=1",
      "is-actionable": true,
      "title": "Daily 1st Party Cookies"
    }
  })], 1)])], 1), !_vm.userHasRole('publisher-fp') ? _c('Row', {
    attrs: {
      "type": "flex",
      "gutter": 24
    }
  }, [_c('Col', {
    staticClass: "small-widget-col",
    attrs: {
      "lg": 12,
      "md": 24,
      "xs": 24,
      "sm": 24
    }
  }, [_c('WidgetTile', {
    attrs: {
      "api": "wcookies/cookies26",
      "widget-style": "BAR",
      "is-actionable": true
    }
  })], 1)], 1) : _vm._e()], 1) : _c('NoScope'), _c('WidgetFocus')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }