<template>
  <div class="cookies-overview">
    <div v-if="scopeQs">
      <Row style="margin-bottom:24px"
           type="flex"
           :gutter="24"
      >
        <Col :xs="24"
             :sm="24"
             :md="12"
             v-if="userHasRole('publisher-fp')"
        >
          <ApiWidgetFactory
            namespace="cookie"
            :description="{title: $t('global.cookie.exempted_cookies')}"
            :excluded-filters="['isExempted']"
            :params="{limit: 1, is_exempted: true, isFirstParty: 1}"
            type="ONE_VALUE"
          />
        </Col>
      </Row>
      <Row
        v-if="!userHasRole('publisher-fp')"
        style="margin-bottom:24px"
        type="flex"
        :gutter="24"
      >
        <Col
          :xs="24"
          :sm="24"
          :md="8"
        >
          <ApiWidgetFactory
            namespace="cookie"
            :description="{title: $t('global.cookie.all_detected')}"
            :params="{limit: 1}"
            type="ONE_VALUE"
          />
        </Col>
        <Col
          :md="8"
          :xs="24"
          :sm="24"
        >
          <ApiWidgetFactory
            namespace="cookie"
            :description="{title: $t('global.cookie.first_party_t')}"
            :params="{isFirstParty: 1, limit: 1}"
            type="ONE_VALUE"
          />
        </Col>
        <Col
          :xs="24"
          :sm="24"
          :md="8"
        >
          <ApiWidgetFactory
            namespace="cookie"
            :description="{title: $t('global.cookie.third_party_t')}"
            :params="{isFirstParty: 0, limit: 1}"
            type="ONE_VALUE"
          />
        </Col>
      </Row>   
      <Row
        v-if="!userHasRole('publisher-fp')"
        type="flex"
        :gutter="24"
      >
        <Col
          :xs="24"
          :sm="24"
          :md="12"
        >
          <WidgetTile
            api="wcookies/cookies08"
            :is-actionable="true"
            style="min-height:500px"
          />
        </Col>
        <Col
          :xs="24"
          :sm="24"
          :md="12"
        >
          <WidgetTile
            api="wcookies/cookies15"
            :is-actionable="true"
          />
        </Col>
      </Row>
      <Row
        v-else
        type="flex"
      >
        <Col
          v-if="!userHasRole('publisher-fp')"
          :xs="24"
          :sm="24"
          :md="12"
        >
          <div>
            <ApiWidgetFactory
              namespace="cookie"
              :description="{title: $t('global.cookie.exempted_cookies')}"
              :excluded-filters="['isExempted']"
              :params="{limit: 1, is_exempted: true, isFirstParty: 1}"
              type="ONE_VALUE"
            />
            <br> 
            <WidgetTile
              api="wcookies/cookiesCountForSimilarProperties?isFirstParty=1"
              :widget-style="'BAR'"
              :is-actionable="true"
            /> 
          </div>
        </Col>
        
        <Col
          :lg="12"
          :md="24"
          :sm="24"
          :xs="24"
          class="small-widget-col"
        >
          <div>
            <ApiWidgetFactory
              namespace="cookie"
              :description="{title: $t('Trackers dropped without explicit choice')}"
              :params="{limit: 1, cmpScenarioOutcomes: ['NO_ACTION']}"
              type="ONE_VALUE"
            />
            <br>
            <WidgetTile
              api="wcookies/cookies08?isFirstParty=1"
              :is-actionable="true"
              style="min-height:500px"
              title="Daily 1st Party Cookies"
            />
          </div>
        </Col>
      </Row>
      <Row
        v-if="!userHasRole('publisher-fp')"
        type="flex"
        :gutter="24"
      >
        <Col
          :lg="12"
          :md="24"
          :xs="24"
          :sm="24"
          class="small-widget-col"
        >
          <WidgetTile
            api="wcookies/cookies26"
            widget-style="BAR"
            :is-actionable="true"
          />
        </Col>
        <!-- <Col
          :lg="12"
          :md="24"
          :xs="24"
          :sm="24"
          class="small-widget-col"
        >
          <WidgetTile
            api="wcookies/cookies27"
            :is-actionable="true"
          />
        </Col> -->
      </Row>
    </div>
    <NoScope v-else />
    <WidgetFocus />
  </div>
</template>

<script>
import pageConfig from '@/mixins/pageConfig'
import tableColumn from '@/mixins/tableColumn'
import WidgetTile from '@/components/ui/WidgetTile'
import WidgetFocus from '@/components/ui/WidgetFocus'
import ApiWidgetFactory from '@/components/ui/ApiWidgetFactory'
import NoScope from '@/components/ui/NoScope'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'Index',
  mixins: [pageConfig, tableColumn],
  components: {
    ApiWidgetFactory,
    WidgetTile,
    WidgetFocus,
    NoScope
  },
  data () {
    return {
     
    }
  },
  methods: {
    ...mapActions({
      cookieGetCookies: 'cookie/getCookies',
      exportCsv: 'cookie/exportCsv'
    }),
    getCookieAgeInMonthOrDays (days) {
      days = parseInt(days)
      if (typeof days !== 'number') return 'N/A'
      if (days > -31 && days < 31) return days + ' ' + this.$t('global.time.days')
      const month = Math.round(days / 30.5)
      return month + ' ' + this.$t('global.time.months')
    }
  },
  computed: {
    ...mapGetters({
      scopeQs: 'scope/qs',
      scopeSelectedProperty: 'scope/selectedProperty',
      scopeSelectedDaterange: 'scope/selectedDaterange',
      userHasRole: 'user/hasRole',
    }),
  }
}
</script>
